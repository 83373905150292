import { type FC } from 'react';

import MuiLink from '@mui/material/Link';
import Image from 'next/image';
import Link from 'next/link';

const Logo : FC = () => (
  <Link href="/home" passHref>
    <MuiLink>
      <Image
        src='/logo.jpg'
        alt="YONA Search logo"
        width={845}
        height={182}
      />
    </MuiLink>
  </Link>
);

export default Logo
