import { FormEvent, type FC, useState } from "react";

import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Link as MuiLink,
  Box,
  Grid,
  Typography,
  Container,
  Alert,
} from "@mui/material";
import { FirebaseError } from "firebase/app";
import { signInWithEmailAndPassword } from "firebase/auth";
import Link from "next/link";
import { useRouter } from "next/router";
import { useAuth } from "reactfire";

import Copyright from "@/components/organisms/layouts/copyRight";
import Logo from "@/components/organisms/layouts/logo";

const SignIn: FC = () => {
  const router = useRouter();
  const auth = useAuth();
  const [message, setMessage] = useState(router.query.message);
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      await signInWithEmailAndPassword(
        auth,
        data.get("email") as string,
        data.get("password") as string
      );
      router.push("/home");
    } catch (e) {
      if (
        e instanceof FirebaseError &&
        (e.code === "auth/user-not-found" || e.code === "auth/wrong-password")
      ) {
        setMessage("メールアドレスまたはパスワードが正しくありません");
      } else {
        setMessage("予期せぬエラーが発生しました");
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Logo />
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          {message && <Alert severity="error">{message}</Alert>}
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="メールアドレス"
            name="email"
            autoComplete="email"
            autoFocus
            type="email"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="パスワード"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            サインイン
          </Button>
          <Grid container>
            <Grid item xs={8}>
              <Link href="/password-reset" passHref>
                <MuiLink variant="body2">パスワードを忘れた</MuiLink>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
};
export default SignIn;
