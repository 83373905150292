import { type FC } from "react";

import { 
  Typography,
  Link as MuiLink,
  type TypographyProps,
 } from '@mui/material'
import Link from "next/link";

const Copyright: FC<TypographyProps> = (props) => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link href="https://www.yonasearch.com" passHref>
        <MuiLink color="inherit">YONASearch</MuiLink>
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

export default Copyright;
