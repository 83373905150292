import type { ReactElement } from 'react';

import type { NextPageWithLayout } from '@/types';

import Layout from "@/components/layouts/anonymous";
import Template from "@/components/templates";

const Page : NextPageWithLayout = () => (<Template />);

Page.getLayout = (page: ReactElement) => (<Layout>{page}</Layout>);

export default Page;
